import React, { Fragment, PropsWithChildren } from 'react'
import { Modal } from '../../contexts/ModalContext'
import { getMemberFee } from '../../../functions/lib/prices'
import { FormEnrollmentValues } from '../forms'
import { useLectures } from '../forms/useLectures'
import Backdrop from './Backdrop'

import * as styles from './modals.module.sass'

const formatPrice = Intl.NumberFormat('cs', {
  style: 'currency',
  currency: 'CZK',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format

interface EnrollmentResumeModalProps {
  data: FormEnrollmentValues
  price: number
  onConfirm: () => void
  onCancel?: () => void
}

export default function EnrollmentResumeModal({
  data,
  price,
  onCancel,
  onConfirm,
}: PropsWithChildren<EnrollmentResumeModalProps>) {
  const {
    getLecturesPrice,
    getDiscountTrainers,
    getDiscountHelpers,
    getDiscountForLectures,
    getDiscountForLecture,
    getDiscountForSiblings,
    getSiblingDiscount,
    getHelperChildDiscount,
    checkCapacity,
  } = useLectures()

  const discount1 = getDiscountTrainers(data.members)
  const discountLectures = getDiscountForLectures(data.members)
  const discountHelpers = getDiscountHelpers(data.members)
  const discountSiblings = getDiscountForSiblings(data.members)
  const discountedPrice =
    price - discount1 - discountLectures - discountHelpers - discountSiblings
  const hasCapacity = checkCapacity(data.members)

  return (
    <Modal>
      <Backdrop onClick={onCancel}>
        <div className={styles.Enrollment}>
          <div className={styles.content}>
            <h2>Rekapitulace přihlášky</h2>
            <h3>Členové</h3>
            <table className={styles.member}>
              <tbody>
                {data.members.map((member) => (
                  <Fragment key={member.rc}>
                    <tr className={styles.headerRow}>
                      <th />
                      <td>
                        {member.name} {member.surname}
                      </td>
                    </tr>
                    <tr>
                      <th>Rodné číslo</th>
                      <td>{member.rc}</td>
                    </tr>
                    <tr>
                      <th>Tričko</th>
                      <td>{member.tshirt ? member.tshirtSize : 'NE'}</td>
                    </tr>
                    <tr>
                      <th>Oddělení</th>
                      <td className={styles.lectures}>
                        {member.lecture.join(', ')}
                      </td>
                    </tr>
                    {member.sibling && (
                      <tr>
                        <th>Sourozenec</th>
                        <td className={styles.lectures}>ANO</td>
                      </tr>
                    )}
                    <tr>
                      <th>Oddílové příspěvky</th>
                      <td>
                        {formatPrice(getLecturesPrice(member.lecture) ?? 0)}
                      </td>
                    </tr>
                    <tr>
                      <th>Spolkový příspěvek</th>
                      <td>{formatPrice(getMemberFee(member.rc) ?? 0)}</td>
                    </tr>
                    {!!getHelperChildDiscount(member) && (
                      <tr>
                        <th>Sleva na dítě pomahatele</th>
                        <td className={styles.lectures}>
                          {formatPrice(-getHelperChildDiscount(member))}
                        </td>
                      </tr>
                    )}
                    {!!getSiblingDiscount(member) && (
                      <tr>
                        <th>Sleva sourozenec</th>
                        <td className={styles.lectures}>
                          {formatPrice(-getSiblingDiscount(member))}
                        </td>
                      </tr>
                    )}
                    {!!getDiscountForLecture(member) && (
                      <tr>
                        <th>Sleva na cvičení</th>
                        <td className={styles.lectures}>
                          {formatPrice(-getDiscountForLecture(member))}
                        </td>
                      </tr>
                    )}
                  </Fragment>
                ))}
              </tbody>
            </table>

            <h3>Zákonný zástupce</h3>
            <table>
              <tbody>
                <tr>
                  <th>Email</th>
                  <td>{data.email}</td>
                </tr>
                <tr>
                  <th>Telefon</th>
                  <td>{data.phone}</td>
                </tr>
                <tr>
                  <th>Ulice a č.p.</th>
                  <td>{data.address}</td>
                </tr>
                <tr>
                  <th>Obec</th>
                  <td>{data.city}</td>
                </tr>
              </tbody>
            </table>
            {data.note && (
              <>
                <h3>Poznámka</h3>
                <p className={styles.note}>{data.note}</p>
              </>
            )}
            <h3>Platba celkem</h3>
            <table>
              <tbody>
                <tr className={styles.headerRow}>
                  <th />
                  <td>{price ? formatPrice(discountedPrice) : '-'}</td>
                </tr>
                {Boolean(discount1) && (
                  <>
                    <tr>
                      <th>Cena před slevou</th>
                      <td>{formatPrice(price)}</td>
                    </tr>
                    <tr>
                      <th>Sleva pro cvičitele</th>
                      <td>{formatPrice(-discount1)}</td>
                    </tr>
                  </>
                )}
                {Boolean(discountLectures) && (
                  <tr>
                    <th>Sleva na cvičení</th>
                    <td>{formatPrice(-discountLectures)}</td>
                  </tr>
                )}
                {Boolean(discountHelpers) && (
                  <tr>
                    <th>Sleva pro dítě pomahatele</th>
                    <td>{formatPrice(-discountHelpers)}</td>
                  </tr>
                )}
                {Boolean(discountSiblings) && (
                  <tr>
                    <th>Sleva pro sourozence</th>
                    <td>{formatPrice(-discountSiblings)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <footer>
            {price == null && (
              <p>Kapacita jednoho z vybranných oddělení se bohužel zaplnila.</p>
            )}
            <button onClick={onCancel}>Upravit</button>
            <button
              className={styles.primary}
              onClick={onConfirm}
              disabled={!hasCapacity}
            >
              Odeslat
            </button>
          </footer>
        </div>
      </Backdrop>
    </Modal>
  )
}
