import { Variant } from 'framer-motion'

export interface PresenceVariants extends Record<string, Variant> {
  initial: Variant
  enter: Variant
  exit: Variant
}

export const PRESENCE_LABELS = {
  initial: 'initial',
  animate: 'enter',
  exit: 'exit'
}
