import React from 'react'
import { motion } from 'framer-motion'
import { useLockBodyScroll } from '../../hooks'
import { PRESENCE_LABELS, PresenceVariants } from '../../lib/animations'
import { PointerEventHandler, PropsWithChildren, useCallback } from 'react'

import * as styles from './modals.module.sass'

interface BackdropProps {
  onClick?: () => void
}

export default function Backdrop({ children, onClick }: PropsWithChildren<BackdropProps>) {
  useLockBodyScroll()
  const handleClick: PointerEventHandler<HTMLDivElement> = useCallback((e) => {
    if (e.target === e.currentTarget && typeof onClick === 'function') {
      onClick()
    }
  }, [onClick])

  return (
    <motion.div
      className={styles.Backdrop}
      variants={backdropVariants}
      {...PRESENCE_LABELS}
      onPointerDown={handleClick}
    >
      {children}
    </motion.div>
  )
}

const backdropVariants: PresenceVariants = {
  initial: {
    opacity: 0,
    display: 'none',
  },
  enter: {
    opacity: 1,
    display: 'flex',
    transition: {
      when: 'beforeChildren',
      delayChildren: 0.2
    }
  },
  exit: {
    opacity: 0,
    transition: {
      when: 'afterChildren',
      delayChildren: 0.2
    }
  }
}
