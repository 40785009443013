import { useCallback, useMemo, useState } from 'react'
import { navigate } from 'gatsby'
import debounce from 'lodash/debounce'
import flatten from 'lodash/flatten'

import { sendEnrollmentToNetlify, signupEnrollment } from '../../api'
import { scrollTop } from '../../utils'
import { encode, FormEnrollmentValues } from '.'
import { useLectures } from './useLectures'

export function useEnrollmentSubmit() {
  const [sending, setSending] = useState(false)
  const [formData, setFormData] = useState<FormEnrollmentValues | null>(null)
  const [target, setTarget] = useState<string>('')
  const { getTotalPrice } = useLectures()

  const price = useMemo(
    () => (!formData ? 0 : getTotalPrice(formData.members)),
    [formData]
  )

  const onSubmit = useCallback(
    async (data: FormEnrollmentValues, e) => {
      e.preventDefault()
      const form = e.target

      setTarget(form.getAttribute('action'))
      setFormData(data)
    },
    [setTarget, setFormData]
  )

  const handleConfirm = useCallback(async () => {
    if (!formData) return

    const { members, ...owner } = formData

    console.log(members, owner)

    const data = flatten(
      members.map((member) =>
        member.lecture.map((lecture) => ({
          ...member,
          lecture,
          ...owner,
        }))
      )
    )

    scrollTop()
    setSending(true)

    const saveResult = await signupEnrollment(data)

    if (saveResult.status === 200) {
      for (const item of data) {
        try {
          await sendEnrollmentToNetlify(
            encode({
              'form-name': 'enrollment',
              subject:
                'Zápis 2023/2024: Odeslání formuláře z webu sokolpisek.cz',
              ...item,
            })
          )
        } catch (error) {
          console.error(error)
          alert('Odeslání se nepovedlo, zkuste to znovu')
          setSending(false)
          return
        }
      }
      debounce(() => navigate(target), 1000)()
    } else {
      setSending(false)
      alert('Odeslání se nepovedlo, zkuste to prosím znovu.')
    }
  }, [setSending, formData, target])

  const handleCancel = useCallback(() => {
    setFormData(null)
  }, [setFormData])

  return {
    onSubmit,
    formData,
    price,
    sending,
    handleConfirm,
    handleCancel,
  }
}
