/**
 * Sezóna 2022/2023
 */
import React from 'react'

import { DefaultPageProps } from '../../types'
import Layout from '../../components/layout'
import { FormEnrollment } from '../../components/forms/formEnrollment'
import { useEnrollmentSubmit } from '../../components/forms/useEnrollmentSubmit'
import EnrollmentResumeModal from '../../components/modals/EnrollmentResumeModal'
import Throbber from '../../components/throbber'

import * as styles from '../../styles/forms.module.sass'

const Zapis = ({ location }: DefaultPageProps) => {
  const { onSubmit, sending, formData, handleConfirm, handleCancel, price } =
    useEnrollmentSubmit()

  return (
    <Layout location={location} customClass="enrollment">
      <header>
        <h1>Zápis na cvičební rok 2023/2024</h1>
      </header>
      <section className="page-content">
        {sending ? (
          <>
            <h4>Vteřinku, vaše přihláška se odesílá</h4>
            <div className={styles.loading}>
              <Throbber />
            </div>
          </>
        ) : (
          <>
            <p>
              <strong>Důležitá upozornění: </strong>
            </p>
            <ol>
              <li>Vyplňujte údaje člena, kterého chcete zapsat.</li>
              <li>
                Přihlášku vyplňte pro všechny zájemce najednou (tlačítko PŘIDAT
                DALŠÍHO ČLENA).
              </li>
              <li>
                Potvrzení o přihlášení s pokyny k platbě dorazí na Váš e-mail do
                5 minut.
              </li>
            </ol>
            <small>
              V případě potíží kontaktujte{' '}
              <a href="https://sokolpisek.cz/kontakt">předsedu odboru</a>.
              <br />
              Data se posílají zabezpečeným spojením a jsou námi zpracována v
              souladu s GDPR.
            </small>

            {formData && (
              <EnrollmentResumeModal
                data={formData}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                price={price}
              />
            )}
            <FormEnrollment onSubmit={onSubmit} />
          </>
        )}
      </section>
    </Layout>
  )
}

export default Zapis
